export class OperationalError extends Error {
  constructor(message: string, options: { [key: string]: any } = {}) {
    super(message, options);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#custom_error_types
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, OperationalError);
    }

    this.name = "OperationalError";

    // https://github.com/microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, OperationalError.prototype);
  }
}
